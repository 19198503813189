import React from 'react'
import ArticleFinancer from '../Article/ArtcileFinancer'

export default function Financer() {
    return (

        <div>
            <ArticleFinancer />
        </div>
    )
}
